<!-- 报名规则详情——考级指南 -->
<template>
    <div class="ruleDetails">
      <div v-html="richtext"></div>
        <!-- <div class="top">
            <span style="font-size: 16px; font-weight: bold;">考级指南</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <div class="" style="margin-left: 28px;font-size: 14px;">
            {{date}}  {{frequency}}次浏览  分类：{{sort}}
        </div>
       
        <div class="xian"></div>

        <div class="title">
            <div class="">
                中国美术学院全国美术等级考试考级指南
            </div>
            <span>2022年寒假美术书法等级考试（深圳考区）</span>
        </div>
         
        <div class="content">
           
            <div class="one">
                <div class="">
                    一、开考科目、级别：
                </div>
                <span>
                    设人物、山水、花鸟、速写、素描、水彩画、水粉画、软笔书法、硬笔书法、漫画十个科目，各科目均设1—9级，9级为最高级别；
                </span>
            </div>
          
            <div class="two">
                <div class="">
                    二、报名对象：
                </div>
                <span>
                    美术书法爱好者，年龄、名额不限；
                </span>
            </div>
           
            <div class="three">
                <div class="">
                    三、报考时间和报名方式：
                </div>
                <span>
                    报名时间：2021年12月10日至2021年12月31日
                </span>
                <span>
                    咨询电话：88830206（报名处）  18922809902（考级办）
                </span>
            </div>
         
            <div class="four">
                <div class="">
                    四、报名细则：
                </div>
                <span>
                    1.考生报名时需正确填写个人信息，交本人近期正面免冠白底电子版证件照片，上传照片容量小于100k（像素：水平413*垂直551）。<br/>
                    2.每位考生需交一张身份证复印件（正面即可）。<br/>
                    3.考生报名时对照考纲结合自身水平选择相应级别，不可以跳级考试。<br/>
                    4.报考科目填写需跟简章一致。一经报名，恕不更改科目、级别和退费。缺考作自动弃权，恕不退费和补考。<br/>
                    5.各级别收费标准如下：
                </span>
                
                <div class="" style="margin-top: 25px;">
                    <table border="1" cellspacing="0" cellpadding="0">
                        <tr>
                            <td>一级100元</td>
                            <td>二级110元</td>
                            <td>三级120元</td>
                            <td>四级150元</td>
                            <td>五级160元</td>
                        </tr>
                        <tr>
                            <td>六级170元</td>
                            <td>七级200元</td>
                            <td>八级220元</td>
                            <td>九级240元</td>
                        </tr>
                    </table>
                </div>
            </div>
           
           
            <div class="five">
                <div class="">
                    五、考试时间：
                </div>
                <span>
                    2022 年 2 月 12 - 13 日
                </span>
            </div>
           
           
            <div class="six">
                <div class="">
                    六、考级地点：
                </div>
                <span>
                    深圳市福田区宏威路38号 深圳书画艺术学院
                </span>
                <br/>
                <span>
                    (彩田村西北角，地铁龙华线莲花北A1出口，公交“彩田村”站)
                </span>
                <div class="Map">
                    <img src="../../assets/map.png" >
                </div>
            </div>
           
            <div class="seven">
                <div class="">
                    七、考生须知：
                </div>
                <span>
                    1.考卷由中国美术学院考级中心专家统一命题，在中国美院统一阅卷，考试后根据领证通知按时到指定地点领取证书。<br/>
                    2.中国美术学院考级中心推出升降级制度（试行），即根据考生实际水平评级和发放，考级费升不补，降不退。<br/>
                    3.考生拿到准考证后要仔细进行核对，如有疑问请与学院考级办联系。<br/>
                    4.考生须持准考证并按照准考证上规定的时间、地点和考场参加考试，遵守考试纪律。升级考试要提交原等级证书的复印件。<br/>
                    5.考生根据考纲带好考试用工具（笔、墨、砚、毡垫、颜料）。考场提供各科目考纲规定考试用纸一张。<br/>
                    6.考生需将准考证副联贴于试卷背面（标签内容包括：姓名、报考科目、级别、考点名称、承办机构名称，低幼年龄考生会由监考老师帮助贴上）。废卷不得带出考场。<br/>
                    7.考级机构对考级作品有展览、研究、摄影、录像、出版、宣传和处置权，考级作品一律不退。
                </span>
                
            </div>
        </div>
          
        <div class="xian"></div>
        <div class="buttom">
            <div class="">
                &copy2015 深圳书画学院 考生考级报名系统 All rights reserved
            </div>
            <div class="">
                服务热线：0755-8883 0206
            </div>
        </div>     -->
    </div>
</template>

<script>
    import axios from "axios"
    export default{
        data() {
            return{
               date:"5/26/2016 6:29:10 PM",//时间
               frequency:"8650",//浏览次数
               sort:"考级中心",//分类
               richtext:''
            }
        },
        created() {
            axios.get('https://www.szart.cn/api/public/index.php/api/common/webinfo').then(res=>{
                this.richtext = res.data.data.guide
            })
        },
        methods:{
            goBack() { //返回
                this.$router.go(-1);
            },
        }
    }
</script>

<style scoped="scoped">
    .ruleDetails{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    /* 上面部分 */
    .top {
        /* padding-top: 25px; */
        padding: 25px 24px 0px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    .xian{
        margin-top: 10px;
        border-bottom: 1px solid #ccc;
    }
    /* 主体内容 */
    /* 标题 */
    .title{
        margin-top: 20px;
        text-align: center;
        padding-bottom: 15px;
    }
    .title div{
        font-size: 20px;
        font-weight: bold;
        color: #333;
        padding-bottom: 5px;
    }
    .title span{
        font-size: 14px;
    }
    /* 内容 */
    .content{
        margin-left: 28px;
        font-size: 14px;
        flex: 1;
    }
    .one,.two,.three,.four,.five,.six,.seven{
        margin-bottom: 15px;
    }
    .content span{
        display: inline-block;
        margin:10px 0px 10px 20px ;
    }
    table{
        text-align: center;
        border-collapse:collapse;
    }
    table td{
        height: 35px;
        width: 130px;
    }
    /* 地图 图片*/
    .map{
        width: 558px;
        height:360px;
    }
    .map img{
        width: 100%;
        height: 100%;
    }
    /* 底部 */
    .buttom {
        height: 50px;
        line-height: 50px;
        padding: 0px 25px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
    }
</style>
